<template>
  <div class="mt-20">
    <img src="@/assets/img/Oops404.png" alt="" />
    <div class="text-center font-bold text-xl text-gray-600">Thiết bị của bạn có vấn đề</div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
