<template>
  <div>
    <div class="bg">
      <h1>About</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "BiaogeAbout",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.bg {
  height: 100vh;
  display: flex;
  justify-content: center;
  background: url("../assets/img/bg.png") no-repeat center center;
}
</style>
